.sentiment-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.header-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.is-mobile {
  display: none;
}

.is-desktop {
  display: inline;
}

@media (max-width: 800px) {
  .is-mobile {
    display: inline;
  }
  
  .is-desktop {
    display: none;
  }
  
  .header-container {
    flex-direction: column;
  }
}
